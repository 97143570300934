import styles from './Form.module.scss';
import InputMask from 'react-input-mask';
import { ReactElement, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Loader } from '../../../../Loader/Loader';
import emailjs from '@emailjs/browser';

export const Form = () => {
    const [name, setName] = useState('');
    const [cpf, setCpf] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (
        e: React.FormEvent<HTMLFormElement> | React.BaseSyntheticEvent<Event>
    ) => {
        e.preventDefault();
        let formSubmitSuccess = true;
        setFormButton(<Loader />);
        if (
            name === '' ||
            cpf === '' ||
            phone === '' ||
            email === '' ||
            message === ''
        ) {
            formSubmitSuccess = false;
            if (name === '') changeInputClass(name, 'name');
            if (cpf === '') changeInputClass(cpf, 'cpf');
            if (phone === '') changeInputClass(phone, 'phone');
            if (email === '') changeInputClass(email, 'email');
            if (message === '') changeInputClass(message, 'message');
        }
        if (!isValidCPF(cpf)) {
            changeInputClass(cpf, 'cpf');
            formSubmitSuccess = false;
        }
        if (phone.indexOf('_') !== -1) {
            changeInputClass(phone, 'phone');
            formSubmitSuccess = false;
        }
        if (
            email === '' ||
      email.indexOf('@') === -1 ||
      email.indexOf('.') === -1 ||
      email.indexOf('.') - email.indexOf('@') === 1
        ) {
            changeInputClass(email, 'email');
            formSubmitSuccess = false;
        }

        if (formSubmitSuccess) {
            emailjs
                .sendForm(
                    'clientToUs',
                    'template_b03w3wk',
                    e.target,
                    'g6CSlOJKOHXHkjbvv'
                )
                .then(
                    () => {
                        toast.success('Sua mensagem foi enviada com sucesso!');
                        setName('');
                        setCpf('');
                        setPhone('');
                        setEmail('');
                        setMessage('');
                        setFormButton('ENVIAR');
                        // Email para o cliente confirmando o contato
                        emailjs
                            .sendForm(
                                'usToClient',
                                'template_uyew6jd',
                                e.target,
                                'g6CSlOJKOHXHkjbvv'
                            )
                            .then()
                            .catch((err) => {
                                console.log(err);
                            });
                    },
                    (err) => {
                        toast.error('Ocorreu um erro e o formulário não pôde ser enviado.');
                        setFormButton('ENVIAR');
                        console.log(err);
                    }
                );
            e.target.reset();
        } else {
            toast.error('Verifique se os campos estão preenchidos corretamente.');
            setFormButton('ENVIAR');
        }
    };

    function isValidCPF(cpf: string) {
        if (typeof cpf !== 'string') return false;
        cpf = cpf.replace(/[\s.-]*/gim, '');
        if (
            !cpf ||
      cpf.length !== 11 ||
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
        ) {
            return false;
        }
        let soma = 0;
        let resto;
        for (let i = 1; i <= 9; i++)
            soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
        resto = (soma * 10) % 11;
        if (resto === 10 || resto === 11) resto = 0;
        if (resto !== parseInt(cpf.substring(9, 10))) return false;
        soma = 0;
        for (let i = 1; i <= 10; i++)
            soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
        resto = (soma * 10) % 11;
        if (resto === 10 || resto === 11) resto = 0;
        if (resto !== parseInt(cpf.substring(10, 11))) return false;
        return true;
    }

    const changeInputClass = (value: string, id: string) => {
        const el = document.querySelector(`#${id}`);
        el?.classList.add('input-error');
        if (id === 'name') {
            setName('');
        } else if (id === 'cpf') {
            setCpf('');
        } else if (id === 'phone') {
            setPhone('');
        } else if (id === 'email') {
            setEmail('');
        } else if (id === 'message') {
            setMessage('');
        }
    };

    const changeEmptyInputClass = (value: string, id: string) => {
        const el = document.querySelector(`#${id}`);
        if (value.trim() === '') {
            el?.classList.add('input-error');
        } else {
            el?.classList.remove('input-error');
        }
    };

    const [formButton, setFormButton] = useState<string | ReactElement>('ENVIAR');

    return (
        <form
            className={styles.form}
            onSubmit={handleSubmit}
            method="POST"
            encType="text/plain"
        >
            <label htmlFor="name">NOME</label>
            <input
                placeholder="Digite seu nome"
                id="name"
                name="name"
                type="text"
                onChange={(e) => {
                    setName(e.target.value);
                    changeEmptyInputClass(e.target.value, 'name');
                }}
                value={name}
            />

            <div className={styles.form__flex}>
                <div>
                    <label htmlFor="cpf">CPF</label>
                    <InputMask
                        mask="999.999.999-99"
                        placeholder="000.000.000-00"
                        name="cpf"
                        id="cpf"
                        onChange={(e) => {
                            setCpf(e.target.value);
                            changeEmptyInputClass(e.target.value, 'cpf');
                        }}
                        value={cpf}
                    ></InputMask>
                </div>
                <div>
                    <label htmlFor="phone">TELEFONE</label>
                    <InputMask
                        mask="(99) 99999-9999"
                        placeholder="(00) 00000-0000"
                        name="phone"
                        id="phone"
                        onChange={(e) => {
                            setPhone(e.target.value);
                            changeEmptyInputClass(e.target.value, 'phone');
                        }}
                        value={phone}
                    ></InputMask>
                </div>
            </div>

            <label htmlFor="email">EMAIL</label>
            <input
                name="email"
                type="text"
                placeholder="exemplo@gmail.com"
                id="email"
                onChange={(e) => {
                    setEmail(e.target.value);
                    changeEmptyInputClass(e.target.value, 'email');
                }}
                value={email}
            />

            <label htmlFor="message">MENSAGEM</label>
            <textarea
                name="message"
                cols={30}
                rows={10}
                placeholder="Digite sua mensagem"
                id="message"
                onChange={(e) => {
                    setMessage(e.target.value);
                    changeEmptyInputClass(e.target.value, 'message');
                }}
                value={message}
            ></textarea>
            <button type="submit" disabled={typeof formButton === 'string' ? false : true}>{formButton}</button>
            <Toaster
                position="bottom-right"
                reverseOrder={false}
                toastOptions={{
                    error: {
                        style: {
                            fontSize: '18px',
                            fontFamily: 'os-light',
                        },
                        iconTheme: {
                            primary: 'white',
                            secondary: 'red',
                        },
                    },
                    success: {
                        style: {
                            fontSize: '18px',
                            fontFamily: 'os-light',
                        },
                    },
                }}
            />
        </form>
    );
};
