import styles from './ContactTop.module.scss';
import ContactIMG from '../../../../assets/images/contactTop.png';

export const ContactTop = () => {
    return (
        <section className={styles.contact__top}>
            <div className={styles.contact__top__left}>
                <h1>Contato</h1>
                <iframe
                    title="Localização da VivaCred"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3644.0278517267743!2d-46.49251558444486!3d-24.030082984954635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce1f09c88c9345%3A0x4eb3b1fe6a6b7edf!2sViva%20Cred!5e0!3m2!1spt-BR!2sbr!4v1630324493438!5m2!1spt-BR!2sbr"
                    width="540"
                    height="300"
                    style={{ border: 0 }}
                    loading="lazy"
                ></iframe>
                <p className={styles.contact__top__address}>
                    <a
                        href="https://goo.gl/maps/1bUfDBJcpmUd4NyUA"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
            Av. Presidente Kennedy, 8.579 - Vila Mirim / PG
                    </a>
                </p>
                <p className={styles.contact__top__phone}>
                    <a className={styles.contact__top__phone__link} href="tel:3472-2726">
            3472-2726
                    </a>
                </p>
                <h2>Horário de atendimento</h2>
                <legend>Seg - Sex: 08h30 -18h</legend>
                <legend>Sáb - Dom - Feriados: Fechados.</legend>
            </div>
            <div className={styles.contact__top__right}>
                <img src={ContactIMG} alt="Ilustração de contato" />
            </div>
        </section>
    );
};
