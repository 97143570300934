import styles from './Footer.module.scss';
import logoIMG from '../../assets/images/logo.png';
import { Link } from 'react-scroll';

export const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footer__top}>
                <div className={styles.left}>
                    <h3>Viva Cred</h3>
                    <legend>
                        <a href={'/'}>Home</a>
                    </legend>
                    <legend>
                        <a href={'/servicos'}>Serviços</a>
                    </legend>
                    <legend>
                        <a href="/">Contato</a>
                    </legend>
                    <legend>
                        <a href="/">Avaliações</a>
                    </legend>
                </div>
                <div className={styles.right}>
                    <h3>Contato</h3>
                    <legend>
                        <a
                            href="https://www.facebook.com/vivacred.emprestimos"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
              Facebook
                        </a>
                    </legend>

                    <legend>
                        <a
                            href="https://api.whatsapp.com/send?phone=5513997121143"
                            target="_BLANK"
                            rel="noopener noreferrer"
                        >
              WhatsApp
                        </a>
                    </legend>

                    <legend>
                        <a
                            href="https://www.instagram.com/vivacred_solucoesfinanceiras/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
              Instagram
                        </a>
                    </legend>

                    <legend>
                        <a href="tel:3472-2726">3472-2726</a>
                    </legend>
                </div>
                <div className={styles['footer__img--div']}>
                    <Link to="header" smooth={true} duration={1000}>
                        <img src={logoIMG} alt="Logo da VivaCred" />
                    </Link>
                </div>
            </div>
            <div className={styles.footer__copy}>
        Todos os direitos reservados &copy; 2022
            </div>
        </footer>
    );
};
