import styles from './Assessments.module.scss';
import AssessmentsIMG from '../../../assets/images/assessments.png';
import assessmentsData from './../../../data/fakeAPI.json';
import { AssessmentsCard } from './Card/AssessmentsCard';
import { useEffect, useState } from 'react';
import UserIMG from './../../../assets/images/user.png';
import { BiRefresh } from 'react-icons/bi';

export const Assessments = () => {
    const [cards, setCards] = useState(assessmentsData.slice(0, 4));

    useEffect(() => {
        const shuffledData = shuffleArray(assessmentsData);
        const initialCards = shuffledData.slice(0, 4);
        setCards(initialCards);
    }, []);

    function shuffleArray<T>(array: T[]): T[] {
        const newArray = [...array];
        for (let i = newArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
        }
        return newArray;
    }

    const refreshCards = () => {
        const shuffledData = shuffleArray(assessmentsData);
        const newCards = shuffledData.slice(0, 4);
        setCards(newCards);
    };
    return (
        <section className={styles.assessments} id="assessments">
            <img src={AssessmentsIMG} alt="" className={styles.assessments__img} />
            <div className={styles.assessments__info}>
                <h1 className="assessments-title">
          Avaliações
                    <BiRefresh
                        className={styles.refresh}
                        onClick={refreshCards}
                        size={35}
                    />
                </h1>

                <div className={styles.assessments__cards}>
                    {cards.map((card) => (
                        <AssessmentsCard
                            key={card.id}
                            name={card.clientName}
                            text={card.feedback}
                            src={UserIMG}
                        />
                    ))}
                </div>
                <div className={styles.assessments__button}>
                    <a
                        href="https://www.facebook.com/vivacred.emprestimos/reviews"
                        rel="noopener noreferrer"
                        target="_BLANK"
                        className="assessments-button-link"
                    >
            ver mais
                    </a>
                </div>
            </div>
        </section>
    );
};
