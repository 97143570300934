import styles from './FaqCard.module.scss';
import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
} from '@chakra-ui/react';
import { IFaqCardProps } from '../../../../interfaces/IFaqCardProps';

export const FaqCard = ({ question, answer }: IFaqCardProps) => {
    return (
        <AccordionItem className={styles.faq__item}>
            <h2>
                <AccordionButton className={styles.faq__button}>
                    <Box as="span" flex="1" textAlign="left" className={styles.faq__box}>
                        {question}
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4} className={styles.faq__answer}>
                {answer}
            </AccordionPanel>
        </AccordionItem>
    );
};
