import { ILoaderProps } from '../../interfaces/ILoaderProps';
import styles from './Loader.module.scss';

export const Loader = ({ size, color }: ILoaderProps) => {
    return (
        <span
            className={styles.loader}
            style={{ width: size, height: size, borderBottomColor: color }}
        ></span>
    );
};
