import styles from './About.module.scss';
import AboutIMG from '../../../assets/images/about.png';

export const About = () => {
    const anoAtual = new Date().getFullYear();
    return (
        <section className={styles.about}>
            <div>
                <h1>Quem somos?</h1>

                <p>
                    Fundada em 2013, estamos no mercado de soluções financeiras há {anoAtual - 2013} anos
                    proporcionando aos nossos clientes um atendimento personalizado onde
                    oferecemos o melhor serviço para a realização dos seus sonhos,
                    reorganização financeira e etc.
                    <br />
                    <span className={styles.underline}>Missão:</span>
                    Diversificar produtos relacionados à empréstimo consignado de forma
                    que seja útil de acordo com o perfil dos nossos clientes, pois
                    possuímos uma grande variedade de bancos parceiros.
                    <br />
                    <span className={styles.underline}>Valores:</span>
                    Compromisso e integridade na oferta da melhor opção aos nossos
                    clientes. Transforme você também a sua vida com a Viva Cred,
                    orientando a melhor escolha e acolhendo suas necessidades sempre.
                    <br />
                    <span className={styles.bold}>Viva feliz, Viva Cred!</span>
                </p>
            </div>
            <img src={AboutIMG} alt='Ilustração "Quem somos?" VivaCred' />
        </section>
    );
};
