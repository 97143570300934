import styles from './Services.module.scss';
import ServicesIMG from '../../../assets/images/servicos.png';
// import cards from './../../../data/services.json';
import { ServicesCard } from './ServicesCard/ServicesCard';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { IService } from '../../../interfaces/IServices';
import { Loader } from '../../Loader/Loader';
import config from '../../../config';


export const Services = () => {
    const [dataServices, setDataServices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}/servicos/select.servicos.php`);

                if (response.data.status === 200) {
                    setDataServices(response.data.data.slice(0, 6));
                }
                setIsLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    return (
        <section className={styles.services}>
            <img src={ServicesIMG} alt="" className={styles.services__img} />
            <div className={styles.services__info}>
                <h1 style={isLoading ? { marginBottom: 100 } : {}} className={styles.services__title}>Nossos serviços</h1>

                {
                    isLoading
                        ?
                        <Loader size='150px' color='blue' />
                        :
                        <div className={styles.services__cards}>
                            {dataServices.map((service: IService) =>
                                service.servico_descricao && (
                                    <ServicesCard
                                        key={service.servico_id}
                                        src={service.servico_id}
                                        slug={service.servico_slug}
                                        title={service.servico_titulo}
                                        text={service.servico_descricao}
                                    />
                                )
                            )}
                        </div>
                }
            </div>
        </section>
    );
};
