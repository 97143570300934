import './BlogServicesCard.scss';
import { IBlogServicesCard } from '../../../interfaces/IBlogServicesCard';
import { useEffect } from 'react';

export const BlogServicesCard = ({
    src,
    title,
    isBlue,
    link,
    isBigger,
}: IBlogServicesCard) => {
    useEffect(() => {
        const backToDefaultColor = (card: Element) => {
            let cardBottom;
            if (isBigger) {
                cardBottom = card.querySelector('.blog-services-card-bottom');
            } else {
                cardBottom = card.querySelector(
                    '.blog-product-page-card-bottom'
                );
            }
            if (cardBottom instanceof HTMLElement) {
                cardBottom.style.backgroundColor = '#EDEDED';
                cardBottom.style.color = 'black';
            }
        };

        const changeColor = (card: Element) => {
            const cardBottom = card.querySelector('.blog-services-card-bottom');
            if (cardBottom instanceof HTMLElement) {
                if (cardBottom.classList.contains('blog-blue-card')) {
                    cardBottom.style.backgroundColor = '#4B6EEB';
                    cardBottom.style.color = 'white';
                } else {
                    cardBottom.style.backgroundColor = '#EB4D4B';
                    cardBottom.style.color = 'white';
                }
            }
        };

        const smallCardChangeColor = (card: Element) => {
            const cardBottom = card.querySelector(
                '.blog-product-page-card-bottom'
            );
            if (cardBottom instanceof HTMLElement) {
                cardBottom.style.backgroundColor = '#a1a1a1';
            }
        };

        const blogServicesCards = document.querySelectorAll(
            '.blog-services-card'
        );
        blogServicesCards.forEach((card) => {
            card.addEventListener('mouseover', () => changeColor(card));
            card.addEventListener('mouseout', () => backToDefaultColor(card));
        });
        // #
        const productSinglePageCards = document.querySelectorAll(
            '.blog-product-page-card'
        );
        productSinglePageCards.forEach((card) => {
            card.addEventListener('mouseover', () =>
                smallCardChangeColor(card)
            );
            card.addEventListener('mouseout', () => backToDefaultColor(card));
        });
    });

    return (
        <a
            href={link}
            className={
                isBigger
                    ? 'blog-services-card-link'
                    : 'blog-services-right-card-link'
            }
        >
            <div
                className={
                    isBigger ? 'blog-services-card' : 'blog-product-page-card'
                }
            >
                <div
                    className={
                        isBigger
                            ? 'blog-services-card-top'
                            : 'blog-product-page-card-top'
                    }
                    style={{
                        backgroundImage: `url(${require(`../../../assets/images/blog/main/${src}.png`)})`,
                    }}
                ></div>
                <div
                    className={`${isBlue ? 'blog-blue-card' : 'blog-red-card'} 
                                ${
                                    isBigger
                                        ? 'blog-services-card-bottom'
                                        : 'blog-product-page-card-bottom'
                                }`}
                >
                    {title}
                </div>
            </div>
        </a>
    );
};
