import styles from './Header.module.scss';
import { useEffect, useState } from 'react';
import Logo from '../../assets/images/logo2.png';
import { Link } from 'react-scroll';
import { useLocation } from 'react-router-dom';

export const Header = () => {
    const [isMobileNavBarVisible, setIsMobileNavBarVisible] =
    useState<boolean>(false);
    const location = useLocation();

    const animateNavLinks = (links: Array<HTMLElement>) => {
        links.forEach((link, index) => {
            link.style.animation
                ? (link.style.animation = '')
                : (link.style.animation = `${styles.navLinkFade} .8s ease forwards ${
                    index / 15 + 0.2
                }s`);
        });
    };

    const handleMobileNav = () => {
        setIsMobileNavBarVisible(!isMobileNavBarVisible);
        const navLinks = Array.from(
            document.querySelectorAll('header ul li')
        ) as Array<HTMLElement>;
        animateNavLinks(navLinks);
    };

    const backIfIsNotHome = () => {
        if (location.pathname === '/') return;
        else {
            window.location.href = '/';
        }
    };

    useEffect(() => {
        const navLinks = Array.from(
            document.querySelectorAll('header ul li')
        ) as Array<HTMLElement>;

        navLinks.forEach((link) => {
            link.addEventListener('click', handleMobileNav);
        });

        return () => {
            navLinks.forEach((link) => {
                link.removeEventListener('click', handleMobileNav);
            });
        };
    });

    return (
        <header className={styles.header} id="header">
            <a href="/">
                <img
                    src={Logo}
                    className={styles.header__logo}
                    alt="Logo da empresa VivaCred"
                />
            </a>
            <div
                className={`${styles.mobile__menu} ${
                    isMobileNavBarVisible ? styles.active : ''
                }`}
                onClick={handleMobileNav}
            >
                <div className={styles.line1}></div>
                <div className={styles.line2}></div>
                <div className={styles.line3}></div>
            </div>
            <nav
                className={`${styles.header__nav} ${
                    isMobileNavBarVisible ? styles.active : ''
                }`}
            >
                <ul className={styles.header__ul}>
                    <li>
                        <a href="/">home</a>
                    </li>
                    <li>
                        <a href="/servicos">serviços</a>
                    </li>
                    <li>
                        <Link
                            to={'assessments'}
                            smooth={true}
                            duration={1000}
                            onClick={backIfIsNotHome}
                        >
              avaliações
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={'faq'}
                            smooth={true}
                            duration={1000}
                            onClick={backIfIsNotHome}
                        >
              dúvidas
                        </Link>
                    </li>
                    <Link
                        to={'contact'}
                        smooth={true}
                        duration={1000}
                        onClick={backIfIsNotHome}
                    >
                        <li id={styles.li__contato}>contato</li>
                    </Link>
                </ul>
            </nav>
        </header>
    );
};
