import styles from './ServicePage.module.scss';
import { useParams, useNavigate } from 'react-router-dom';
// import services from '../../../data/services.json';
import { NotFound } from '../../../pages/NotFound';
import { BlogServicesCard } from '../BlogServicesCard/BlogServicesCard';
import { useEffect, useState } from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { IDisplayedProducts } from '../../../interfaces/IDisplayedProducts';
import axios from 'axios';
import { IService } from '../../../interfaces/IServices';
import { Loader } from '../../Loader/Loader';
import config from '../../../config';

export const ServicePage = () => {
    const { slug } = useParams();
    const navigate = useNavigate();

    const [displayedProducts, setDisplayedProducts] = useState<IService[]>([]);
    const [services, setDataServices] = useState<IService[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const product = services.find((p: IService) => p.servico_slug === slug);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}/servicos/select.servicos.php`);

                if (response.data.status === 200) {
                    const fetchedServices = response.data.data.slice(0, 6);
                    fetchedServices.map((service: IService) => {
                        if (service.servico_blogtexto && typeof service.servico_blogtexto === 'string') {
                            service.servico_blogtexto = JSON.parse(service.servico_blogtexto);
                        }
                    });
                    setDataServices(fetchedServices);

                    const otherProducts = fetchedServices.filter((p: IService) => p.servico_slug !== slug);
                    setDisplayedProducts(otherProducts);

                    setIsLoading(false);

                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
        window.scrollTo(0, 0);
    }, [slug]);

    if (!isLoading && !product) return <NotFound />;
    return (
        isLoading
            ?
            (
                <section className={styles.service}>
                    <Loader size='200px' />
                </section>
            )

            :
            (<section className={styles.service}>
                <div className={styles.service__info}>
                    {isLoading
                        ?
                        <Loader size="100px" />
                        :
                        null}
                    <div
                        className={styles.backpage__div}
                        onClick={() => navigate('/servicos')}
                    >
                        <AiOutlineArrowLeft className={styles.backpage__arrow} />
                    </div>
                    <h1>{product?.servico_blogtitulo}</h1>
                    {Array.isArray(product?.servico_blogtexto) ? (
                        product?.servico_blogtexto.map((paragraph: any, index: any) => (
                            <p className={styles.text} key={index}>
                                {paragraph}
                            </p>
                        ))
                    ) : (
                        <p className={styles.text}>{product?.servico_blogtexto}</p>
                    )}

                    <a
                        href="http://api.whatsapp.com/send?phone=5513997121143"
                        target="_BLANK"
                        rel="noopener noreferrer"
                    >
                        <div className={styles.service__button}>
                            Solicitar agora
                        </div>
                    </a>
                </div>
                <a
                    href="http://api.whatsapp.com/send?phone=5513997121143"
                    target="_BLANK"
                    rel="noopener noreferrer"
                >
                    <div className={styles['service__button--mobile']}>
                        Solicitar agora
                    </div>
                </a>
                <div className={styles.right}>
                    <h3>Veja também:</h3>
                    <div className={styles.right__services}>
                        {displayedProducts.map((card: IService) => (
                            <BlogServicesCard
                                isBigger={false}
                                key={card.servico_id}
                                title={card.servico_titulo}
                                src={card.servico_slug}
                                link={`/servicos/${card.servico_slug}`}
                            />
                        ))}
                    </div>
                </div>
            </section>
            )
    );
};