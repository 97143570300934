import styles from './Faq.module.scss';
import questions from './../../../data/questions.json';
import { FaqCard } from './FaqCard/FaqCard';
import { Accordion } from '@chakra-ui/react';

export const Faq = () => {
    return (
        <section className={styles.faq} id="faq">
            <h1>Dúvidas frequentes</h1>
            <Accordion
                defaultIndex={[0]}
                allowMultiple
                className={styles.faq__accordion}
            >
                {questions.map((data) => (
                    <FaqCard
                        question={data.question}
                        answer={data.answer}
                        key={data.id}
                    />
                ))}
            </Accordion>
        </section>
    );
};
