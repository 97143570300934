import styles from './ContactBottom.module.scss';
import ContactBottomIMG from '../../../../assets/images/contactBottom.png';
import { Form } from './Form/Form';

export const ContactBottom = () => {
    return (
        <section className={styles.contact__bottom}>
            <div className={styles.contact__bottom__left}>
                <img src={ContactBottomIMG} alt="" />
                <div className={styles.contact__bottom__medias}>
                    <div className={styles.contact__bottom__media}>
                        <a
                            href="http://api.whatsapp.com/send?phone=5513997121143"
                            target="_BLANK"
                            className={styles['contact__bottom__media--link']}
                            rel="noopener noreferrer"
                        >
              WhatsApp
                        </a>
                    </div>
                    <div className={styles.contact__bottom__media}>
                        <a
                            href="https://www.facebook.com/vivacred.emprestimos"
                            target="_BLANK"
                            className={styles['contact__bottom__media--link']}
                            rel="noopener noreferrer"
                        >
              Facebook
                        </a>
                    </div>
                    <div className={styles.contact__bottom__media}>
                        <a
                            href="https://www.instagram.com/vivacred_solucoesfinanceiras/"
                            target="_BLANK"
                            className={styles['contact__bottom__media--link']}
                            rel="noopener noreferrer"
                        >
              Instagram
                        </a>
                    </div>
                </div>
            </div>
            <div className={styles.form__div}>
                <h1>fale conosco</h1>
                <Form />
            </div>
        </section>
    );
};
