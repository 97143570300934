import styles from './Contact.module.scss';
import { ContactTop } from './Top/ContactTop';
import { ContactBottom } from './Bottom/ContactBottom';

export const Contact = () => {
    return (
        <section className={styles.contact} id="contact">
            <ContactTop />
            <ContactBottom />
        </section>
    );
};
