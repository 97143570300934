import styles from './AssessmentsCard.module.scss';
import starIMG from '../../../../assets/images/star.svg';
import { IAssessmentsCardProps } from '../../../../interfaces/IAssessmentsCardProps';

export const AssessmentsCard = ({ name, text, src }: IAssessmentsCardProps) => {
    return (
        <div className={styles.assessments__card}>
            <div className={styles.assessments__card__top}>
                <img src={src} alt="" className={styles.assessments__card__img} />
                <div className={styles.assessments__card__stars}>
                    <img
                        src={starIMG}
                        alt="estrela"
                        className={styles.assessments__card__star}
                    />
                    <img
                        src={starIMG}
                        alt="estrela"
                        className={styles.assessments__card__star}
                    />
                    <img
                        src={starIMG}
                        alt="estrela"
                        className={styles.assessments__card__star}
                    />
                    <img
                        src={starIMG}
                        alt="estrela"
                        className={styles.assessments__card__star}
                    />
                    <img
                        src={starIMG}
                        alt="estrela"
                        className={styles.assessments__card__star}
                    />
                </div>
            </div>
            <p className={styles.assessments__card__text}>{text}</p>
            <p className={styles.assessments__card__name}>{name}</p>
        </div>
    );
};
