import styles from './Banks.module.scss';
import './Banks.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';

export const Banks = () => {
    const responsiveOptions = {
        0: {
            items: 3,
        },
        400: {
            items: 4,
        },
        600: {
            items: 4,
        },
        950: {
            items: 6,
        },
        1300: {
            items: 7,
        },
        1900: {
            items: 10,
        },
    };
    return (
        <section className={styles.banks}>
            <h1>Bancos parceiros</h1>
            <div className={styles.banks__carousel}>
                <OwlCarousel
                    items={8} // VER NA TELA
                    nav
                    dots
                    margin={50}
                    responsive={responsiveOptions}
                >
                    <div className="item">
                        <h4>
                            <img
                                src={require('../../../assets/images/banks/bradesco.png')}
                                alt="Bradesco Financiamentos"
                                className="banks-img"
                            />
                        </h4>
                    </div>

                    <div className="item">
                        <h4>
                            <img
                                src={require('../../../assets/images/banks/daycoval.png')}
                                alt="Daycoval"
                                className="banks-img"
                            />
                        </h4>
                    </div>
                    <div className="item">
                        <h4>
                            <img
                                src={require('../../../assets/images/banks/santander.png')}
                                alt="Santander"
                                className="banks-img"
                            />
                        </h4>
                    </div>
                    <div className="item">
                        <h4>
                            <img
                                src={require('../../../assets/images/banks/caixa.png')}
                                alt="Caixa Economica Federal"
                                className="banks-img"
                            />
                        </h4>
                    </div>
                    <div className="item">
                        <h4>
                            <img
                                src={require('../../../assets/images/banks/pan.png')}
                                alt="Banco Panamericano"
                                className="banks-img"
                            />
                        </h4>
                    </div>
                    <div className="item">
                        <h4>
                            <img
                                src={require('../../../assets/images/banks/olé.png')}
                                alt="Olé Consignado"
                                className="banks-img"
                            />
                        </h4>
                    </div>
                    <div className="item">
                        <h4>
                            <img
                                src={require('../../../assets/images/banks/safra.png')}
                                alt="Banco Safra"
                                className="banks-img"
                            />
                        </h4>
                    </div>
                    <div className="item">
                        <h4>
                            <img
                                src={require('../../../assets/images/banks/bmg.png')}
                                alt="Banco BMG"
                                className="banks-img"
                            />
                        </h4>
                    </div>
                    <div className="item">
                        <h4>
                            <img
                                src={require('../../../assets/images/banks/itau.png')}
                                alt="Itaú"
                                className="banks-img"
                            />
                        </h4>
                    </div>
                    <div className="item">
                        <h4>
                            <img
                                src={require('../../../assets/images/banks/crefaz.png')}
                                alt="Crefaz"
                                className="banks-img"
                            />
                        </h4>
                    </div>
                    <div className="item">
                        <h4>
                            <img
                                src={require('../../../assets/images/banks/c6.png')}
                                alt="C6"
                                className="banks-img"
                            />
                        </h4>
                    </div>
                    <div className="item">
                        <h4>
                            <img
                                src={require('../../../assets/images/banks/banrisul.png')}
                                alt="Banrisul"
                                className="banks-img"
                            />
                        </h4>
                    </div>
                    <div className="item">
                        <h4>
                            <img
                                src={require('../../../assets/images/banks/agibank.png')}
                                alt="Agibank"
                                className="banks-img"
                            />
                        </h4>
                    </div>
                    <div className="item">
                        <h4>
                            <img
                                src={require('../../../assets/images/banks/facta.png')}
                                alt="Facta"
                                className="banks-img"
                            />
                        </h4>
                    </div>
                    <div className="item">
                        <h4>
                            <img
                                src={require('../../../assets/images/banks/mercantil.png')}
                                alt="Mercantil"
                                className="banks-img"
                            />
                        </h4>
                    </div>
                    <div className="item">
                        <h4>
                            <img
                                src={require('../../../assets/images/banks/banco do brasil.png')}
                                alt="Banco do Brasil"
                                className="banks-img"
                            />
                        </h4>
                    </div>
                    <div className="item">
                        <h4>
                            <img
                                src={require('../../../assets/images/banks/ccb.png')}
                                alt="CCB"
                                className="banks-img"
                            />
                        </h4>
                    </div>
                    <div className="item">
                        <h4>
                            <img
                                src={require('../../../assets/images/banks/cetelem.png')}
                                alt="Cetelem"
                                className="banks-img"
                            />
                        </h4>
                    </div>
                    <div className="item">
                        <h4>
                            <img
                                src={require('../../../assets/images/banks/crefisa.png')}
                                alt="Crefisa"
                                className="banks-img"
                            />
                        </h4>
                    </div>
                    <div className="item">
                        <h4>
                            <img
                                src={require('../../../assets/images/banks/jb.png')}
                                alt="JB"
                                className="banks-img"
                            />
                        </h4>
                    </div>
                    <div className="item">
                        <h4>
                            <img
                                src={require('../../../assets/images/banks/valor.png')}
                                alt="Valor Financiamentos"
                                className="banks-img"
                            />
                        </h4>
                    </div>
                </OwlCarousel>
            </div>
        </section>
    );
};
