import styles from './Services.module.scss';
// import dataServices from '../../../data/services.json';
import { BlogServicesCard } from '../BlogServicesCard/BlogServicesCard';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Loader } from '../../Loader/Loader';
import { IService } from '../../../interfaces/IServices';
import config from '../../../config';

export const BlogServices = () => {
    const [dataServices, setDataServices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}/servicos/select.servicos.php`);
                if (response.data.status === 200) {
                    setDataServices(response.data.data);
                }
                setIsLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    return (
        <section className={styles.services}>
            <h1>SERVIÇOS DISPONÍVEIS</h1>
            <h2 style={isLoading ? { marginBottom: 100 } : {}}>Clique no produto desejado para obter detalhes</h2>
            {
                isLoading ? <Loader size='100px' /> : null
            }
            <div className={styles.services__cards}>
                {
                    !isLoading
                        ?
                        dataServices.map((service: IService) => (
                            <BlogServicesCard
                                isBigger={true}
                                key={service.servico_id}
                                isBlue={service.servico_azul == 1 ? true : false}
                                title={service.servico_titulo}
                                src={service.servico_slug}
                                link={`/servicos/${service.servico_slug}`}
                            />
                        ))
                        :
                        null
                }
            </div>
        </section>
    );
};
