import styles from './NotFoundPage.module.scss';
import errorIMG from '../../assets/images/error404.png';
import { AiFillExclamationCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

export const NotFoundPage = () => {
    const navigate = useNavigate();

    return (
        <section className={styles.notfound}>
            <div className={styles.notfound__left}>
                <div className={styles['notfound__left--top']}>
                    <h1>Oops!</h1>
                    <h2>Página não encontrada...</h2>
                    <button onClick={() => navigate('/')}>
            Voltar para página inicial
                    </button>
                </div>
                <div className={styles['notfound__left--bottom']}>
                    <AiFillExclamationCircle
                        style={{ color: '#EB4D4B' }}
                        size={27}
                    />
                    <p>ERRO 404</p>
                </div>
            </div>
            <div className={styles.notfound__right}>
                <img src={errorIMG} alt="Imagem de erro 404." />
            </div>
        </section>
    );
};
